<template>
  <div class="home-slider">
    <div
      v-for="(template, index) in sliderResumeTemplates"
      :key="index"
      class="home-slide"
    >
      <figure class="home-slide__img" :data-id="template.id">
        <img
          :src="
            template.image_name ||
            require('@/assets/images/default-template-img.svg')
          "
          alt=""
        />
      </figure>
    </div>
  </div>
</template>

<script>
import Flickity from "flickity";
import FlickityTransformer from "flickity-transformer";
import { ROUT_PATH } from "@/helpers/rout-constants";
import { mapGetters } from "vuex";

export default {
  name: "HomeSlider",

  computed: {
    sliderInitialSlide() {
      return window.innerWidth > 992 ? 2 : 1;
    },
    ...mapGetters({
      templates: "templates/resumeTemplates",
    }),
  },

  data() {
    return {
      templateSlider: null,
      templateSliderTransform: null,
      sliderResumeTemplates: [
        require("@/assets/images/home/home-slider/slide1.png"), // Minimalist
        require("@/assets/images/home/home-slider/slide4.png"), // Pure
        require("@/assets/images/home/home-slider/slide2.png"), // prime
        require("@/assets/images/home/home-slider/slide3.png"), // Timeless
        require("@/assets/images/home/home-slider/slide5.png"), // Patriot
        require("@/assets/images/home/home-slider/slide6.png"), // Obsidian
        require("@/assets/images/home/home-slider/slide7.png"), // Bold Black
      ],
      ROUT_PATH,
    };
  },
  async created() {
    localStorage.removeItem("selected_from_home_page");
    const _this = this;
    Flickity.createMethods.push("mouse");
    Flickity.prototype.mouse = function () {
      this.on("staticClick", function (event, pointer, cellElement, cellIndex) {
        const id = cellElement
          .querySelector(".home-slide__img")
          .getAttribute("data-id");
        localStorage.setItem("template_id", id);
        _this.redirect();
      });
    };
    this.createPrevNextSliderCells();

    await this.$store.dispatch("templates/getResumeTemplates", "all");
    this.sliderResumeTemplates = this.templates.filter(
      (template) =>
        template.display_name === "Minimalist" ||
        template.display_name === "Pure" ||
        template.display_name === "Prime" ||
        template.display_name === "Timeless" ||
        template.display_name === "Patriot" ||
        template.display_name === "Obsidian" ||
        template.display_name === "Bold Black"
    );
  },

  mounted() {
    this.templateSlider = new Flickity(".home-slider", {
      wrapAround: false,
      prevNextButtons: false,
      cellAlign: "center",
      draggable: true,
      pageDots: false,
      contain: false,
      adaptiveHeight: false,
      initialIndex: this.sliderInitialSlide,
      freeScroll: true,
    });

    this.templateSliderTransform = new FlickityTransformer(
      this.templateSlider,
      [
        {
          name: "scale",
          stops: [
            [-200, 1],
            [0, 1.5],
            [200, 1],
          ],
        },
      ]
    );
  },

  methods: {
    createPrevNextSliderCells() {
      Flickity.createMethods.push("_createPrevNextCells");

      Flickity.prototype._createPrevNextCells = function () {
        this.on("select", this.setPrevNextCells);

        this.on("dragStart", () => (document.ontouchmove = () => false));
        this.on("dragEnd", () => (document.ontouchmove = () => true));
      };

      Flickity.prototype.setPrevNextCells = function () {
        // remove classes
        changeSlideClasses(this.previousSlide, "remove", "is-previous");
        changeSlideClasses(this.nextSlide, "remove", "is-next");
        // set slides
        this.previousSlide = this.slides[this.selectedIndex - 1];
        this.nextSlide = this.slides[this.selectedIndex + 1];
        // add classes
        changeSlideClasses(this.previousSlide, "add", "is-previous");
        changeSlideClasses(this.nextSlide, "add", "is-next");

        this.on("dragStart", () => (document.ontouchmove = () => false));
        this.on("dragEnd", () => (document.ontouchmove = () => true));
      };

      const changeSlideClasses = function (slide, method, className) {
        if (!slide) return;

        slide.getCellElements().forEach(function (cellElem) {
          cellElem.classList[method](className);
        });
      };
    },
    redirect() {
      this.$router.push(ROUT_PATH.BROWS_RESUME_TEMPLATES);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~flickity/dist/flickity.css";

.flickity-slider {
  -webkit-overflow-scrolling: touch;
}

.home-slider {
  width: 100%;

  .is-previous {
    margin-left: -24px;
  }
  .is-next {
    margin-left: 24px;
  }
  .home-slide {
    width: 50%;
    padding-top: 620/460 * 50%;
    margin: 60px 0;

    @media (min-width: map_get($grid-breakpoints, md)) {
      width: 40%;
      padding-top: 620/460 * 40%;
    }

    @media (min-width: map_get($grid-breakpoints, lg)) {
      width: 20%;
      padding-top: 890/700 * 20%;
      //padding-top: 620/460 * 20%;
      //padding-top: 22.5%;
      //912/1150
    }

    &__img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transition: all 0.3s ease;
      padding: 0 12px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0 24px;
      @media (max-width: map_get($grid-breakpoints, md)) {
        padding: 0 13px;
      }
      img {
        width: 95%;
        object-fit: contain;
        object-position: center center;
        border-radius: 4px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
      }
    }

    &.is-previous {
      .home-slide__img {
        transform: translateX(-17px);

        @media (min-width: map_get($grid-breakpoints, xl)) {
          transform: translateX(-25px);
        }
      }
    }

    &.is-next {
      .home-slide__img {
        transform: translateX(17px);

        @media (min-width: map_get($grid-breakpoints, xl)) {
          transform: translateX(25px);
        }
      }
    }
  }
}
</style>
