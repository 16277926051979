<template>
  <div class="home-wrap">
    <section class="home-intro-section">
      <div class="container">
        <div class="home-intro">
          <figure class="home-intro__bg">
            <img
              src="@/assets/images/home/home-intro-bg.svg"
              alt=""
              class="img-fit-cover"
            />
          </figure>

          <div class="col-lg-6 col-xl-6 home-intro-inner">
            <h1 class="home-intro__title">
              Build the Perfect Résumé that suits your job
            </h1>

            <p class="home-intro__text">
              With Resume-Perfect, you can create a standout, customized resume
              that will get you hired.
            </p>

            <div class="d-flex justify-content-center justify-content-lg-start">
              <AppButton
                v-if="isIncompleteCreation"
                title="Finish My Resume"
                class="home-intro__btn"
                :class="cta_color"
                className="white"
                :style="'background: #' + cta_bg + ' !important;'"
                iconName="TriangleIcon"
                @click.prevent="completeCreation"
              />
              <AppButton
                v-else
                :title="home_cta"
                class="home-intro__btn"
                :class="cta_color"
                className="white"
                :style="'background: #' + cta_bg + ' !important;'"
                iconName="SearchIcon"
                tag="router-link"
                :to="ROUT_PATH.BROWS_RESUME_TEMPLATES"
              />
            </div>
          </div>

          <div class="col-lg-6 col-xl-6">
            <router-link :to="ROUT_PATH.BROWS_RESUME_TEMPLATES">
              <figure class="home-img">
                <img
                  src="@/assets/images/home/home-intro-img.png"
                  alt=""
                  class="d-none d-xl-inline-flex"
                />
                <img
                  src="@/assets/images/home/home-intro-img--mob.png"
                  alt=""
                  class="d-xl-none"
                />
              </figure>
            </router-link>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="feedback">
              <p class="feedback__text">
                “I have never had a resume. The easy process here helped me to
                get a resume without hassle.”
              </p>

              <div class="feedback-author">
                <figure class="feedback-author__ava">
                  <img
                    src="@/assets/images/ava.png"
                    alt=""
                    class="img-fit-cover"
                  />
                </figure>

                <h3 class="feedback-author__name">Ellice</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="home-slider-section">
      <figure class="background-img">
        <img src="@/assets/images/home/template-slider-bg.png" alt="" />
      </figure>

      <div class="container">
        <div class="slider-heading">
          <h2 class="slider-heading__title title-decor">
            Choose from <span class="decor">23 stunning templates</span>
          </h2>
          <p class="slider-heading__subtitle">
            Choose from our best résumé templates. Your résumé, Your way.
          </p>
        </div>

        <HomeSlider />

        <div class="slider-btn-wrap">
          <AppButton
            :title="home_cta"
            className="primary"
            :class="cta_color"
            iconName="SearchIcon"
            :style="'background: #' + cta_bg + ' !important;'"
            tag="router-link"
            :to="ROUT_PATH.BROWS_RESUME_TEMPLATES"
          />
        </div>
      </div>
    </section>

    <section class="home-about-section">
      <figure class="about-img">
        <img src="@/assets/images/home/about-img.png" alt="" />
      </figure>

      <div class="container">
        <div class="row justify-content-end">
          <div class="col-lg-8 col-xl-6">
            <h2 class="about-title title-decor">
              Land your dream job with the help of our
              <span class="decor">Resume Builder</span>
            </h2>

            <p class="about-subtitle">
              Create and select a comprehensive resume, that will help you to
              get a job and search for a great career!
            </p>

            <div
              v-for="(aboutItem, index) of aboutItems"
              :key="index"
              class="about-item"
            >
              <AppIcon
                :componentName="aboutItem.icon"
                class="about-item__icon"
              />
              <h3 class="about-item__title">{{ aboutItem.title }}</h3>
              <p class="about-item__text">
                {{ aboutItem.text }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="home-banner-section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-8">
            <h2 class="home-banner-title">
              Select template and start building your Perfect Resume today!
            </h2>

            <div class="d-flex justify-content-center">
              <AppButton
                :title="home_cta"
                class="home-intro__btn"
                :class="cta_color"
                className="white"
                :style="'background: #' + cta_bg + ' !important;'"
                iconName="SearchIcon"
                tag="router-link"
                :to="ROUT_PATH.BROWS_RESUME_TEMPLATES"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import HomeSlider from "@/components/home/HomeSLider";
import { mapGetters } from "vuex";
import { BROWS_TEMPLATE_NAMES, ROUT_PATH } from "@/helpers/rout-constants";
import ResumeHash from "@/helpers/resume-hash";
import UncompletedCreationStep from "@/helpers/uncompleted-creation-step";
import { mixpanelMixin } from "@/mixins/MixPanelMixin";
import CookieHelper from "@/helpers/cookie-helper";

export default {
  name: "Home",
  components: { HomeSlider },
  mixins: [mixpanelMixin],

  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
      isIncompleteCreation: "auth/isAvailableUncompletedResume",
    }),
  },

  data() {
    return {
      ROUT_PATH,
      aboutItems: [
        {
          title: "It's Fast and Easy to Use",
          text: "Our online resume creator will help you write a perfect resume in minutes",
          icon: "InheritanceIcon",
        },
        {
          title: "Follow Tips From Experts",
          text: "Our experts' tips will show you how to write a resume.",
          icon: "CommentsIcon",
        },
        {
          title: "Flexible Text Editor",
          text: "You will have access to the best text editor available.",
          icon: "PageIcon",
        },
        {
          title: "Track Your Resume",
          text: "Resume-Perfect gives you the freedom to create as many resumes as you like given the fact that it is a desktop app.",
          icon: "ClockFace",
        },
      ],
      home_cta: "Browse Templates",
    };
  },

  async created() {
    this.home_cta = CookieHelper.getFlag("home_cta", "Browse Templates");
    this.cta_bg = CookieHelper.getFlag("cta_bg", "");
    let vm = this;
    let country = CookieHelper.getFlag("country", "");
    let geo = CookieHelper.getFlag("geo", "on");
    this.cta_color = "";

    if (this.cta_bg != "") {
      this.cta_color = "cta_bg";
    }
    if (geo != "on") {
      CookieHelper.setCookie("countrycode", "US", 30);
      CookieHelper.setCookie("country", "United States of America", 30);
    } else {
      if (country == "") {
        let userGeoIP = await this.$store.dispatch("geoip/getUserGeoIP");
        if (userGeoIP.country != undefined && userGeoIP.country != "") {
          CookieHelper.setCookie("countrycode", userGeoIP.countrycode, 30);
          CookieHelper.setCookie("country", userGeoIP.country, 30);
          CookieHelper.setCookie("city", userGeoIP.city, 30);
          CookieHelper.setCookie("state", userGeoIP.state, 30);
          CookieHelper.setCookie("zip", userGeoIP.zip, 30);
          CookieHelper.setCookie("phoneSample", userGeoIP.phone_sample, 30);
        }
      }
    }

    this.mixpanelTrack("V2-HOMEPAGE");
  },

  methods: {
    completeCreation() {
      const stepName = UncompletedCreationStep.get();

      if (!stepName || !ResumeHash.get()) {
        ResumeHash.clear();
        UncompletedCreationStep.clear();
        this.$store.commit("auth/setAvailableUncompletedResume", false);
        this.$router.push({ name: BROWS_TEMPLATE_NAMES.BROWS_RESUME });

        return;
      }

      this.$router.push({ name: stepName });
    },
  },
};
</script>

<style scoped lang="scss" src="./index.scss" />

<style lang="scss" scoped>
.cta_bg {
  border: 1px solid transparent !important;
}
.cta_bg span {
  color: #000 !important;
}
.cta_bg svg {
  fill: #000 !important;
}
</style>
